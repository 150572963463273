<script setup lang="ts">
import { ref } from 'vue';
import { useModalStore } from '@/stores/modal';
import ModalConfirm from '@/components/ModalConfirm.vue';

const modal = useModalStore();

let confirmation = ref(false);
</script>

<template>
  <div class="about mt-3">
    <h1>About</h1>

    <div class="modal-prototype mb-3">
      <h3>Confirm Modal</h3>
      <button class="btn btn-outline-secondary m-1" @click="($event) => modal.open('confirm33')">Confirm33</button>
      <button class="btn btn-outline-secondary m-1" @click="($event) => modal.open('confirm44')">Confirm44</button>

      <p>Confirmation: {{ confirmation }}</p>

      <ModalConfirm id="confirm33" @close="confirmation = $event"></ModalConfirm>
      <ModalConfirm id="confirm44" message="About to be sure?" :ok-button="{label: 'YES', color: 'outline-danger' }" @close="confirmation = $event"></ModalConfirm>

    </div>

    <div class="mb-3">
      <button v-tooltip="'Hello'">Hover</button>
    </div>
    
  </div>
</template>

<style lang="scss" scoped>

@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    margin: 1rem;
    display: flex;
    flex-direction: column;
  }
}
</style>
