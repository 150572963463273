<script setup lang="ts">
import { useShowStore } from '@/stores/show';

const showStore = useShowStore();
</script>

<template>
  <div class="full-page">
    <div class="calendars">
      <template v-for="c in showStore.calendarSvgs" :key="c.id">
        <!-- <svg class="calendar" :class="[showStore.size]" v-html="c.items[0].svg" @click="$router.push({ name: 'showSingle', params: { id: c.id } })"></svg> -->
        <!-- <svg class="calendar" :class="[showStore.size]" v-html="c.items[1].svg" @click="$router.push({ name: 'showSingle', params: { id: c.id } })"></svg> -->
        <svg class="calendar" :class="[showStore.settings.size]" v-html="c.items[0].svg"></svg>
        <svg class="calendar" :class="[showStore.settings.size]" v-html="c.items[1].svg"></svg>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.calendars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.calendar {
  margin: 1rem;
  background-color: #fff;

  &.small {
    height: 400px;
    width: 400px;
  }
  &.large {
    height: 900px;
    width: 900px;
  }
}
</style>
