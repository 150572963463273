import { defineStore } from 'pinia';
import axios from 'axios';
import type { ICalendarSvg2, ICalendarYears, INameValue, ISettings } from '@/types/image';

const STATES = {
  DONE: 1,
  WIP: 2,
  ERROR: 3,
};

const calendarApi = 'https://bikini-web-calendars.azurewebsites.net';
// const calendarApi = 'https://localhost:7241';

const designApi = 'https://design.foundimagepress.com';
// const designApi = 'https://localhost:5009';

export const useShowStore = defineStore('show', {
  state: () => ({
    dataState: STATES.DONE,
    calendarSvgs: [] as ICalendarSvg2[],
    calendarYears: {} as ICalendarYears,
    vendors: [] as INameValue[],
    settings: {} as ISettings
  }),
  actions: {
    async loadSharedData() {
      this.dataState = STATES.WIP;
      return axios
        .get(calendarApi + '/api/utility')
        .then((res) => {
          this.calendarYears = res.data.calendarYears;
          this.vendors = res.data.vendors;
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    // Get calendar SVGs
    getCalendarsAllSvgCached() {
      console.log('getCalendarsAllSvgCached')
      var cached = localStorage.getItem(`bikiniCalendarSvgs.${this.settings.year}.${this.settings.vendorId}`);
      if (cached != null) {
        this.calendarSvgs = JSON.parse(cached);
        console.log('from cache', cached)
        return;
      }
      this.getCalendarsAllSvg();
    },
    getCalendarsAllSvg() {
      this.dataState = STATES.WIP;
      return axios
        .get(`${calendarApi}/api/calendar/svg/${this.settings.year}/${this.settings.vendorId}`)
        .then((res) => {
          this.dataState = STATES.DONE;
          this.calendarSvgs = res.data;
          localStorage.setItem(`bikiniCalendarSvgs.${this.settings.year}.${this.settings.vendorId}`, JSON.stringify(this.calendarSvgs));
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    getTitle() {
      const vendor = this.vendors.find(v => v.value == this.settings.vendorId);
      return `${vendor?.name} ${this.settings.year} Calendars`
    }
  },
});
