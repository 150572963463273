import { createApp, watch } from 'vue';
import { createPinia } from 'pinia';
import { useAuthStore } from './stores/auth';

import './assets/main.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

import * as FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

import App from './App.vue';
import router from './router';
import { useShowStore } from './stores/show';
import { ISettings } from './types/image';

console.log('Initializing Speedo');

const app = createApp(App);

app.use(FloatingVue);

const pinia = createPinia();
app.use(pinia);

const auth = useAuthStore();
auth.init();

const showStore = useShowStore();

const cachedSettings = localStorage.getItem('BikiniShowSettings'); //
if (cachedSettings != null) {
  showStore.settings = JSON.parse(cachedSettings);
} else {
  showStore.settings = { year: 2025, vendorId: 1, size: 'large' } as ISettings;
  localStorage.setItem('BikiniShowSettings', JSON.stringify(showStore.settings));
}

watch(
  () => auth.account?.username,
  async (newValue, oldValue) => {
    await showStore.loadSharedData();
    showStore.getCalendarsAllSvgCached();
    router.push({ name: 'show' });
  },
  { deep: true }
);

app.use(router);

app.mount('#app');

console.log('App Mounted');
