import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth';
import AboutView from '@/views/AboutView.vue';
import ShowViewVue from '@/views/Show.vue';
import ShowSingleVue from '@/views/ShowSingle.vue';

// https://davestewart.co.uk/blog/msal-vue/
// https://github.com/davestewart/msal-vue-demo/tree/main

function beforeEnter(to, from, next){
  const auth = useAuthStore(); // Must invoke within function because not available before.
  if(auth.account){
    return next();
  }
  auth.SignIn().then(()=>next());
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'show',
      component: ShowViewVue
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: AboutView,
      beforeEnter: beforeEnter
    },
    {
      path: '/show',
      name: 'show',
      component: ShowViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/showSingle/:id',
      name: 'showSingle',
      component: ShowSingleVue,
      beforeEnter: beforeEnter
    },
  ]
})

export default router
