<script setup lang="ts">
import { useShowStore } from '@/stores/show';
import { useModalStore } from '@/stores/modal';
import { ISettings } from '@/types/image';
import { ref, onMounted, watch } from 'vue';

interface PropButton {
  label: string;
  color: string;
}

interface Props {
  id: string,
  message?: string;
  closeButton?: PropButton;
  okButton?: PropButton;
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  message: '',
  closeButton: () => ({ label: 'Cancel', color: 'primary' }),
  okButton: () => ({ label: 'OK', color: 'success' }),
});
const emits = defineEmits(['close', 'ok']);

const showStore = useShowStore();
const modalStore = useModalStore();

const settings = ref({} as ISettings);

function close() {
  modalStore.isOpen[props.id] = false;
  emits('close', false);
}

function ok() {
  modalStore.isOpen[props.id] = false;
  emits('close', true, settings.value);
}

watch(
  () => modalStore.isOpen[props.id],
  async (newValue, oldValue) => {
    if(newValue){    
      settings.value = {...showStore.settings};
    }
  },
  { deep: false }
);

</script>

<template>
  <div v-show="modalStore.isOpen[id]" class="modal-backdrop">
    <div class="dialog">
      <div class="card bg-light text-dark">
        <div class="card-header">
          <h5>Settings</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        </div>
        <div class="card-body">
          <p class="card-title">Year</p>
          <select v-model="settings.year" class="form-select form-select-sm ms-3" style="width:100px;">
          <option v-for="year in showStore.calendarYears.years" :value="year.value">{{ year.name }}</option>
        </select>
        <p class="card-title">Vendor</p>
          <select v-model="settings.vendorId" class="form-select form-select-sm ms-3" style="width:200px;">
          <option v-for="vendor in showStore.vendors" :value="vendor.value">{{ vendor.name }}</option>
        </select>
        </div>
        <div class="card-footer">
          <button v-if="props.okButton" class="btn btn-sm ms-2" :class="`btn-${props.okButton.color}`" @click="ok">{{ props.okButton.label }}</button>
          <button class="btn btn-sm btn-outline-secondary ms-2" @click="close">{{ props.closeButton.label }}</button>          
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.dialog {
  position: fixed;
  z-index: 2;
  top: 20%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  background-color: #666;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0;
  }
}

.card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
