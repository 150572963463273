<script setup lang="ts">
import { useModalStore } from '@/stores/modal';

interface PropButton {
  label: string;
  color: string;
}

interface Props {
  id: string,
  message?: string;
  closeButton?: PropButton;
  okButton?: PropButton;
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  message: 'Are you sure?',
  closeButton: () => ({ label: 'Cancel', color: 'primary' }),
  okButton: () => ({ label: 'OK', color: 'success' }),
});
const emits = defineEmits(['close', 'ok']);
const modal = useModalStore();

function close() {
  modal.isOpen[props.id] = false;
  emits('close', false);
}
function ok() {
  modal.isOpen[props.id] = false;
  emits('close', true);
}
</script>

<template>
  <div v-show="modal.isOpen[id]" class="modal-backdrop">
    <div class="dialog">
      <div class="card bg-light text-dark">
        <div class="card-header">
          <h5>About</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        </div>
        <div class="card-body">
          <p class="card-title">Bikini Show v1.1
            <br/>&copy 2024 Found Image Press</p>       
            <a href="https://foundimage.com">foundimage.com</a>   
        </div>
        <div class="card-footer">
          <!-- <button v-if="props.okButton" class="btn btn-sm ms-2" :class="`btn-${props.okButton.color}`" @click="ok">{{ props.okButton.label }}</button> -->
          <button class="btn btn-sm btn-outline-secondary ms-2" @click="close">Close</button>          
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.dialog {
  position: fixed;
  z-index: 2;
  top: 20%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  background-color: #666;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0;
  }
}

.card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
