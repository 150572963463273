<script setup lang="ts">
import { useShowStore } from '@/stores/show';

const showStore = useShowStore();

</script>

<template>
  <span class="status" v-if="showStore.dataState == 1" @click="showStore.getCalendarsAllSvg"><i class="bi-arrow-repeat"></i></span>
  <span class="status wip" v-else-if="showStore.dataState == 2"><i class="bi-arrow-repeat"></i></span>
  <span class="status error" v-else-if="showStore.dataState == 3"><i class="bi-arrow-repeat"></i></span>
</template>

<style lang="scss" scoped>

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flash {
  100% {
    opacity: 0;
  }
}

.status {
  display: none;
  font-size: 31px;
  line-height: 29px;
  border-radius: 0.25rem;
  padding: 0.25rem;
  color: white;
  display: inline-block;

  &.wip {
    color: rgb(49, 163, 184);

    i:before {
    animation: spin 1s linear infinite;
    }
  }

  &.error {    
    background-color: rgb(197, 25, 25);
    animation: flash 1s linear infinite;
  }
}

</style>
