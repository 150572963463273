<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router';
import SyncStatus from '@/components/SyncStatus.vue';
import { useAuthStore } from './stores/auth';
import { useShowStore } from './stores/show';
import { useModalStore } from '@/stores/modal';
import ModalAbout from '@/components/ModalAbout.vue';
import ModalSettings from '@/components/ModalSettings.vue';
import { ISettings } from './types/image';

const auth = useAuthStore();
const showStore = useShowStore();
const modal = useModalStore();

function modalSettingsClose(confirm: boolean, settings: ISettings) {
  if (confirm) {
    showStore.settings = settings;
    localStorage.setItem('BikiniShowSettings', JSON.stringify(showStore.settings))
    showStore.getCalendarsAllSvgCached();
  }
}
</script>

<template>
  <header>
    <div>
      <nav v-if="auth.account?.name">
        <h5 class="me-auto">{{ showStore.getTitle() }}</h5>
        <button class="btn btn-sm btn-outline-secondary me-1" @click="showStore.settings.size = 'large'"><i class="bi-app"></i></button>
        <button class="btn btn-sm btn-outline-secondary me-1" @click="showStore.settings.size = 'small'"><i class="bi-grid"></i></button>
        <button class="btn btn-sm btn-outline-secondary me-1" @click="($event) => modal.open('modalSettings')"><i class="bi-gear"></i></button>
        <button class="btn btn-sm btn-outline-secondary me-1" @click="($event) => modal.open('modalAbout')"><i class="bi-info-lg"></i></button>
        <sync-status />
      </nav>
      <nav v-else>
        <div class="ms-auto">
          <button class="btn btn-sm btn-outline-light" @click="auth.SignIn">Sign In</button>
        </div>
        <div class="ms-2 nav-sync-status">
          <sync-status />
        </div>
      </nav>
    </div>
    <div class="line"></div>
  </header>

  <div class="content">
    <!-- Define key in order to refresh views on parameter change -->
    <RouterView :key="$route.fullPath" />
  </div>

  <ModalAbout id="modalAbout"></ModalAbout>
  <ModalSettings id="modalSettings" @close="modalSettingsClose"></ModalSettings>
</template>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  line-height: 1.5;
  height: 40px;
  background-color: #ddd;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
}

nav {
  display: flex;
  align-items: center;
  padding: 0 1rem;

  a.router-link-exact-active {
    color: #333;
  }

  a.router-link-exact-active:hover {
    background-color: transparent;
  }

  a {
    display: inline-block;
    padding: 0 1rem;
    text-decoration: none;
    color: #ccc;
    transition: 0.4s;
  }

}
</style>
